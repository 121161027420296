<template>
  <div>
    <img src="/collection/cover.jpg" class="cover-img" @click="showIntroduction(0)">
    <span id="viewArtworksBtn" class="round-button" @click="showArtworks">欣赏获奖作品</span>
    <span id="viewNameListBtn" class="round-button" @click="showIntroduction(0)">查看获奖名单</span>
  </div>
</template>
<script>
import MainLayout from "@/layout/MainLayout.vue";
import {ImagePreview} from "vant";

export default {
  name: "Gallery",
  data() {
    return {
      bookPageImageList:["/collection//preface.jpg", "/collection//nameList-1.jpg", "/collection//nameList-2.jpg",
        "/collection//nameList-3.jpg", "/collection//nameList-4.jpg", "/collection//nameList-5.jpg", "/collection//nameList-6.jpg", "/collection//nameList-7.jpg",
        "/collection//nameList-8.jpg", "/collection//nameList-9.jpg", "/collection//nameList-10.jpg", "/collection//nameList-11.jpg", "/collection//back.jpg"],
      isImagePreviewOpen: false
    };
  },
  created() {
    this.$emit(`update:layout`, MainLayout);
    this.$store.dispatch("changeTab", "gallery");
  },
  mounted() {},
  methods: {
    showArtworks() {
      this.$router.push({ name: "ArtWorks" });
    },
    showIntroduction(startPos) {
      this.isImagePreviewOpen = true;
      ImagePreview({
        images: this.bookPageImageList,
        startPosition: startPos,
        showIndicators: true,
        closeable: true,
        loop: false,
        onClose: () => {
          this.isImagePreviewOpen = false;
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isImagePreviewOpen) {
      ImagePreview.close();
      this.isImagePreviewOpen = false;
      next(false);
    } else {
      next();
    }
  }
}
</script>
<style scoped>
.cover-img {
  width: 100%;
}
.game-title {
  padding: 10px;
  font-size: 24px;
  color: #252525;
}
.game-intro {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #252525;
}
.action-container {
  padding: 10px;
  display: flex;
  align-items: center;
}
.view-link {
  font-size: 12px;
  font-weight: 600;
  color: #0800BC;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.arrow-icon {
  width: 20px;
  margin-left: 10px;
}
#viewArtworksBtn {
  position: absolute;
  bottom: 120px;
  left: 90px;
}
#viewNameListBtn {
  position: absolute;
  bottom: 120px;
  right: 50px;
}
.round-button {
  border-radius: 6px;
  border: 1px solid #E1531A;
  padding: 6px;
  font-size: 12px;
  color: #21323A;
}
</style>