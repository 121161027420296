<template>
  <div class="main-container">
    <div class="banner">
      <img src="../assets/head-banner.png" alt="" />
    </div>
    <div class="success">
      <div class="tit">
        参赛证书查询结果
      </div>
      <div class="info">
        <div class="num_box">
          <div class="img">
            <div class="img_l">
              <img src="../assets/num.png" alt="" />
            </div>
            <div class="img_r">
              <img src="../assets/num.png" alt="" />
            </div>
            <div class="number">
              <div>{{ certificateList.length }}</div>
              <div class="txt">张证书</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabN">
        <div class="item active">
          全部
        </div>
      </div>
      <!-- 证书列表 -->
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in certificateList"
          :key="index"
          @click="onPreviewPhoto(item)"
        >
          <div class="img">
            <img :src="certRootUrl + item.certThumbnail" alt="" />
            <div class="item-down"
              @click.stop="
                () => {
                  return;
                }
              "
            >
              <div class="on-down" @click.stop="onDown(item.winnerId)">
                <img src="../assets/down.png" alt="" />
                <span>下载</span>
              </div>
            </div>
          </div>
          <div class="item-c">
            <span v-if="item.awardCategory" style="margin-right: 1.2rem;">{{ item.awardCategory }}</span>
            <span>{{ item.awardType }}</span>
          </div>
          <div class="name">{{ item.winnerName }}</div>
        </div>
      </div>
      <div class="empty" v-if="certificateList.length==0">
        <img src="../assets/empty.svg" alt="" />
        <div class="tip">暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import {baseURL, imageBaseUrl} from "@/services/HttpService";
import {loadCertificates} from "@/services/HomeServices";
import { ImagePreview, Toast } from "vant";
import MainLayout from "@/layout/MainLayout.vue";
export default {
  data() {
    return {
      certRootUrl: imageBaseUrl + "certificates/",
      certificateList: [],
      shortCode: "",
      imgList: [],
    };
  },
  methods: {
    list() {
      if(this.shortCode!==undefined && this.shortCode!=='' && this.shortCode!==null){
        loadCertificates(this.shortCode).then(res => {
          if(res.data.code === 'SUCCESS'){
            this.certificateList = res.data.data;
            this.certificateList.forEach(item => {
              this.imgList.push(this.certRootUrl + item.certPic);
            });
          } else {
            Toast("获取证书列表失败");
          }
        });
      } else {
        Toast("参数不正确");
      }
    },
    onPreviewPhoto(item) {
      let imgList = [];
      imgList.push("/cert-cover.jpg")
      imgList.push(this.certRootUrl + item.certPic);
      ImagePreview({
        images: imgList,
        closeable: true
      });
    },
    onDown(winnerId) {
      window.open(baseURL + `/cert/${winnerId}/cert`, "_self");
    },
  },
  created() {
    this.$emit(`update:layout`, MainLayout);
    this.$store.dispatch("changeTab", "inquiry");
    this.shortCode = this.$route.params.code;
    this.list();
  }
}
</script>
<style scoped lang="less">
.main-container {
  background-color: #ffffff;
  /* prettier-ignore */
  font-size: 12PX;
  margin: 0 auto;
  /* prettier-ignore */
  max-width:900PX;
  .banner {
    width: 100%;
    background: #f7f7f7;
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      /* prettier-ignore */
      max-width: 1000PX;
    }
  }
  .search {
    padding: 3.6rem 2.4rem;
    .title {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }
    .form {
      margin-top: 4rem;
      .form_item {
        display: flex;
        align-items: center;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px solid #ededed;
        font-size: 1.4rem;
        height: 6rem;
        .label {
          width: 12rem;
          padding-right: 1rem;
          .en {
            color: #999;
            margin-top: 0.2rem;
          }
        }
        .value {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .van-cell {
            padding: 0;
          }
        }
      }
      .van-btn {
        background: #14a9d0;
        color: #fff;
        text-align: center;
        line-height: 3.8rem;
        margin-top: 3rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
      }
      .downTime {
        text-align: center;
        color: #666666;
        margin-top: 1.2rem;
      }
    }
  }
  .success {
    padding: 1.2rem 2.4rem;
    .tit {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 1.8rem;
      margin-bottom: 0.5rem;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      .num_box {
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          width: 15rem;
          height: 15rem;
          position: relative;
          .img_l {
            position: absolute;
            top: 0;
            left: -1rem;
            width: 7.5rem;
            height: 15rem;
            overflow: hidden;
            transform: rotate(-10deg);
            img {
              width: 15rem;
              height: 15rem;
            }
          }
          .img_r {
            position: absolute;
            top: 0;
            right: -1rem;
            width: 7.5rem;
            height: 15rem;
            overflow: hidden;
            transform: rotate(10deg);
            img {
              width: 15rem;
              height: 15rem;
              margin-left: -7.5rem;
            }
          }
          .number {
            width: 15rem;
            position: absolute;
            left: 0;
            top: 4.2rem;
            text-align: center;
            font-size: 2rem;
            color: #fdc73c;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .txt {
              font-size: 1.2rem;
              margin-top: 0.3rem;
            }
          }
        }
      }
    }

    .btn {
      width: 12rem;
      display: flex;
      align-items: center;
      border: 1px solid #14a9d0;
      color: #14a9d0;
      height: 3.2rem;
      border-radius: 3.2rem;
      justify-content: center;
      font-size: 1.4rem;
      margin: -2rem auto 0.2rem;
      position: relative;
      z-index: 19;
    }
    .downTime {
      text-align: center;
      color: #666666;
      margin-top: 1.2rem;
    }
    .tabN {
      display: flex;
      font-size: 1.4rem;
      margin-top: 1.8rem;
      border-bottom: 1px solid #ededed;
      .item {
        line-height: 3.6rem;
        margin-right: 2.2rem;
      }
      .active {
        color: #14a9d0;
        font-size: 1.6rem;
        font-weight: 600;
        border-bottom: 1px solid;
      }
    }
    .tab {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.4rem;
      margin-top: 1.8rem;
      .item {
        margin-right: 0.6rem;
        padding: 0.6rem;
        margin-bottom: 0.5rem;
        color: #666;
      }
      .active {
        color: #14a9d0;
        background-color: rgba(235, 246, 251, 1);
      }
    }
    .list {
      display: flex;
      padding: 0 3.2rem;
      flex-wrap: wrap;
      .item {
        width: 50%;
        padding: 0 1.5rem;
        margin-top: 1.6rem;
        .img {
          border: 0.5rem solid #b59f75;
          padding: 0.2rem;
          position: relative;
          img {
            width: 100%;
            display: block;
          }
          .item-down {
            position: absolute;
            left: -0.5rem;
            bottom: -0.5rem;
            right: -0.5rem;
            background-color: rgba(51, 51, 51, 0.72);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #fff;
            height: 3rem;
            padding: 0 1rem;
            .on-down {
              display: flex;
              align-items: center;
              height: 3rem;
              img {
                width: 1.6rem;
                height: 1.6rem;
              }
              span {
                font-size: 1.2rem;
                margin-left: 0.2rem;
              }
            }
          }
        }
        .item-c {
          text-align: center;
          margin-top: 0.8rem;
          font-size: 1.2rem;
          color: #666666;
        }
        .name {
          text-align: center;
          margin-top: 0.3rem;
          word-wrap: break-word;
          font-size: 1.2rem;
          color: #666666;
        }
      }
    }
    .empty {
      img {
        width: 13rem;
        display: block;
        margin: 2.4rem auto 1.2rem;
      }
      .tip {
        text-align: center;
        color: #999;
        margin-top: 1.2rem;
      }
    }
  }
}
/deep/ .van-field__button {
  background: #f0f0f0;
  padding: 0 0.8rem;
  border-radius: 0.5rem;
  font-weight: 600;
  line-height: 3rem;
}
.slide-verify {
  padding: 1.2rem 1.6rem 1.6rem 1.6rem;
  /* prettier-ignore */
  max-width: 500PX;
  position: relative;
  .tit {
    /* prettier-ignore */
    font-size: 18PX;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.2rem;
    /* prettier-ignore */
    line-height: 28PX;
  }
  .close {
    position: absolute;
    right: 1.6rem;
    top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* prettier-ignore */
    height: 28PX;
  }
  .slider-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    /* prettier-ignore */
    height: 200PX;
    .captcha-image {
      /* prettier-ignore */
      width: 300PX;
      /* prettier-ignore */
      height: 150PX;
      overflow: hidden;
      background-size: cover;
      img {
        display: block;
        /* prettier-ignore */
        width: 300PX;
        /* prettier-ignore */
        height: 150PX;
      }
    }
    .slider {
      /* prettier-ignore */
      width: 50PX;
      /* prettier-ignore */
      height: 50PX;
      box-shadow: #83c243 0px 3px 10px 3px;
      position: absolute;
      cursor: pointer;
      /* prettier-ignore */
      top: 50PX;
      background-size: cover !important;
      display: block;
    }
    .slider-drag {
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      /* prettier-ignore */
      height: 36PX;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 0.5rem;
      .tip {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /* prettier-ignore */
        line-height: 34PX;
        text-align: center;
      }
      .left {
        /* prettier-ignore */
        height: 34PX;
        background: #83c243;
        width: 0px;
      }
      .img {
        /* prettier-ignore */
        width: 50PX;
        /* prettier-ignore */
        height: 34PX;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../assets/slider.png");
        /* prettier-ignore */
        background-size: 20PX 20PX;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        /* prettier-ignore */
        border-left: 1PX solid #ededed;
        border-right: 1px solid #ededed;
        z-index: 9;
        top: 0;
        background-color: #fff;
      }
    }
  }
}
.support {
  text-align: center;
  font-size: 1.2rem;
  color: #999999;
  padding-bottom: 3rem;
  margin-top: 6rem;
  a {
    color: #999999 !important;
  }
}
.support_n {
  text-align: center;
  font-size: 1.2rem;
  color: #999999;
  padding-bottom: 3rem;
  margin-top: 3rem;
  a {
    color: #999999 !important;
  }
}
@media screen and (min-width: 500px) {
  .search {
    /* prettier-ignore */
    padding: 36PX 120PX !important;
    .title {
      /* prettier-ignore */
      font-size: 26PX !important;

      /* prettier-ignore */
      margin-top: 24PX;
    }
    .form {
      /* prettier-ignore */
      margin-top: 60PX !important;
      .form_item {
        /* prettier-ignore */
        height: 80PX !important;
        .label {
          /* prettier-ignore */
          font-size: 20PX !important;
          /* prettier-ignore */
          width: 180PX !important;
        }
        /deep/ .van-field__control {
          /* prettier-ignore */
          font-size: 20PX !important;
        }
        /deep/ .van-field__button {
          /* prettier-ignore */
          font-size: 20PX !important;
        }
      }
      .van-btn {
        /* prettier-ignore */
        font-size: 26PX !important;
      }
      .downTime {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
    }
  }
  .success {
    .tit {
      /* prettier-ignore */
      font-size: 26PX !important;
    }
    .info {
      .img {
        .txt {
          /* prettier-ignore */
          bottom: 10PX !important;
          /* prettier-ignore */
          font-size: 20PX !important;
        }
      }
    }
    .btn {
      /* prettier-ignore */
      margin: 16PX auto !important;
      /* prettier-ignore */
      font-size: 20PX !important;
      /* prettier-ignore */
      height: 56PX !important;
      /* prettier-ignore */
      width: 200PX !important;
    }
    .downTime {
      /* prettier-ignore */
      font-size: 20PX !important;
    }
    .tabN {
      .item {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
      .active {
        /* prettier-ignore */
        font-size: 24PX !important;
      }
    }
    .tab {
      /* prettier-ignore */
      margin-top: 24PX !important;
      .item {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
    }
    .list {
      .item {
        .item-c {
          /* prettier-ignore */
          font-size: 18PX !important;
        }
        .name {
          /* prettier-ignore */
          font-size: 18PX !important;
        }
      }
    }
    .empty {
      .tip {
        /* prettier-ignore */
        font-size: 18PX !important;
      }
    }
  }
  .support {
    /* prettier-ignore */
    font-size: 16PX !important;
  }
  .on-down {
    span {
      /* prettier-ignore */
      font-size: 18PX !important;
    }
  }
}
</style>