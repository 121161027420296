<template>
  <div>
    <van-nav-bar title="作品欣赏" left-arrow @click-left="showIntroduction" />
    <div class="search-container">
      <van-search v-model="keyword" @search="onSearch" @cancel="onCancel" placeholder="按姓名搜索" class="search-input" />
      <div @click="onSearch" class="search-button">搜索</div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="works">
        <div v-for="artwork in artworkList" :key="artwork.artworkId" class="work-item">
          <div class="work-frame" @click="showBigPic(artwork)">
            <img :src="artwork.thumbnailUrl" class="work-img" />
          </div>
          <div class="work-info">
            <p class="author">{{ artwork.author }}</p>
            <p class="work-name">{{ artwork.title }}</p>
            <p class="prize">{{ artwork.groupName }} {{ artwork.awardType }}</p>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import MainLayout from "@/layout/MainLayout.vue";
import { ImagePreview } from 'vant';
import {listArtworks} from "@/services/ArtworkService";
import { imageBaseUrl } from "@/services/HttpService";
export default {
  name: "Artworks",
  data() {
    return {
      imageBaseUrl,
      loading:false,
      finished: false,
      artworkList:[],
      pageNum: 1,
      pageSize: 10,
      keyword: "",
      isImagePreviewOpen: false,
    };
  },
  created() {
    this.$emit(`update:layout`, MainLayout);
    this.artworkList = [];
    this.list();
  },
  mounted() {},
  methods: {
    list(){
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        keyword: this.keyword
      };
      this.loading = true;
      listArtworks(params).then(res => {
        this.loading = false;
        if(res.data.code === 'SUCCESS'){
          let records = res.data.data.records;
          if(records.length > 0) {
            records.forEach(item => {
              if(item.thumbnailUrl === undefined || item.thumbnailUrl === null){
                item.thumbnailUrl = "/image-placeholder.png";
              } else {
                item.thumbnailUrl = imageBaseUrl + item.thumbnailUrl;
              }
              if(item.originalWorkUrl === undefined || item.originalWorkUrl === null){
                item.originalWorkUrl = "/image-placeholder.png";
              } else {
                item.originalWorkUrl = imageBaseUrl + item.originalWorkUrl;
              }
              this.artworkList.push(item);
            });
          } else {
            this.finished = true;
          }
        }
      });
    },
    onLoad() {
      if(this.finished===false) {
        this.pageNum++;
        this.list();
      }
    },
    showBigPic(artwork){
      let urls = [];
      urls.push(artwork.originalWorkUrl);
      this.isImagePreviewOpen = true;
      ImagePreview({
        images: urls,
        closeable: true,
        onClose: () => {
          this.isImagePreviewOpen = false;
        }
      });
    },
    showIntroduction(){
      this.$router.push({ name: "Gallery" });
    },
    onSearch() {
      this.artworkList = [];
      this.pageNum = 1;
      this.finished = false;
      this.list();
    },
    onCancel() {
      this.keyword = "";
      this.artworkList = [];
      this.pageNum = 1;
      this.finished = false;
      this.list();
    }
  },
  beforeRouteLeave(to, from, next) {
    if(this.isImagePreviewOpen){
      ImagePreview.close();
      this.isImagePreviewOpen = false;
      next(false);
    } else {
      next();
    }
  }
}
</script>
<style scoped>
.search-container {
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
}
.search-input {
  flex: 1;
  padding: 5px;
  font-size: 14px;
}
.search-button {
  padding: 4px 12px;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 2px;
  cursor: pointer;
  border: 1px solid #BFC1C6;
  border-radius: 4px;
}
.works {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.work-item {
  width: 48%;
  min-height: 360px;
}
.work-frame {
  background-color: #F3F0E9;
  height: calc(48% * 1.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-img {
  max-width: 46vw;
  max-height: 90%;
  height: auto;
  object-fit: contain;
}
.work-info {
  padding: 10px;
}
.author, .prize {
  color: #545351;
  font-size: 12px;
}
.work-name {
  color: #252525;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>