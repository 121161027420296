var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_vm._m(0),_c('div',{staticClass:"success"},[_c('div',{staticClass:"tit"},[_vm._v(" 参赛证书查询结果 ")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"num_box"},[_c('div',{staticClass:"img"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"number"},[_c('div',[_vm._v(_vm._s(_vm.certificateList.length))]),_c('div',{staticClass:"txt"},[_vm._v("张证书")])])])])]),_vm._m(3),_c('div',{staticClass:"list"},_vm._l((_vm.certificateList),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.onPreviewPhoto(item)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.certRootUrl + item.certThumbnail,"alt":""}}),_c('div',{staticClass:"item-down",on:{"click":function($event){$event.stopPropagation();return (() => {
                return;
              }).apply(null, arguments)}}},[_c('div',{staticClass:"on-down",on:{"click":function($event){$event.stopPropagation();return _vm.onDown(item.winnerId)}}},[_c('img',{attrs:{"src":require("../assets/down.png"),"alt":""}}),_c('span',[_vm._v("下载")])])])]),_c('div',{staticClass:"item-c"},[(item.awardCategory)?_c('span',{staticStyle:{"margin-right":"1.2rem"}},[_vm._v(_vm._s(item.awardCategory))]):_vm._e(),_c('span',[_vm._v(_vm._s(item.awardType))])]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.winnerName))])])}),0),(_vm.certificateList.length==0)?_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("../assets/empty.svg"),"alt":""}}),_c('div',{staticClass:"tip"},[_vm._v("暂无数据")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/head-banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_l"},[_c('img',{attrs:{"src":require("../assets/num.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_r"},[_c('img',{attrs:{"src":require("../assets/num.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabN"},[_c('div',{staticClass:"item active"},[_vm._v(" 全部 ")])])
}]

export { render, staticRenderFns }