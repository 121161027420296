import { apiClient, downloadApiClient } from "./HttpService";

// 证书查询
export function getCert(params) {
  return apiClient.get(
    `/cert?countryCode=${params.countryCode}&winnerName=${params.winnerName}`
  );
}
// 获取下载证书信息
export function getCertDown(winnerId) {
  return downloadApiClient.get(`/cert/${winnerId}/cert`);
}
//生成图形滑块验证码
export function sliderCaptcha() {
  return apiClient.get(`/slider-captcha`);
}
//发送验证码
export function verifyCode(params) {
  return apiClient.post(
    `/verify-code?mobile=${params.mobile || ""}&sliderX=${
      params.sliderX
    }&corPosition=${params.corPosition}&loginType=${params.loginType}`
  );
}
//按机构查询学生证书
export function studentCertificates(params) {
  return apiClient.get(
    `/certificate/student-certificates?orgMobile=${params.orgMobile ||
      ""}&verificationCode=${params.verificationCode}&loginType=${
      params.loginType
    }`
  );
}
//批量下载
export function orgPackage(encryptOrgId) {
  return downloadApiClient.get(`/certificate/org-package/${encryptOrgId}`);
}
//按散户登录查询证书
export function selfCertificates(params) {
  return apiClient.get(
    `/certificate/student-selfCertificates?orgMobile=${params.orgMobile}&verificationCode=${params.verificationCode}`
  );
}
//证书查询
export function certificate(params) {
  return apiClient.get(
    `/certificate?concat=${params.concat}&captcha=${params.captcha}`
  );
}

export function verifySmsCode(params) {
  return apiClient.get(
    `/certificate/validate-sms?concat=${params.concat}&captcha=${params.captcha}`
  );
}

//使用短代码查询证书
export function loadCertificates(shortCode) {
  return apiClient.get(`/certificate/${shortCode}`);
}
