<template>
  <div class="main-container">
    <div class="banner">
      <img src="../assets/head-banner.png" alt="" />
    </div>
    <div class="search" v-show="type === 'search'">
      <div class="title">参赛证书查询</div>
      <div class="form">
        <div class="form_item">
          <div class="label">
            <div class="cn">预留手机号</div>
          </div>
          <div class="value">
            <van-field
              type="text"
              label=""
              v-model="searchForm.number"
              placeholder="请输入预留手机号"
            />
          </div>
        </div>
        <div class="form_item">
          <div class="label">
            <div class="cn">验证码</div>
          </div>
          <div class="value">
            <van-field
              type="text"
              label=""
              v-model="searchForm.code"
              placeholder="请输入验证码"
              autocomplete="one-time-code"
              inputmode="numeric"
              maxlength="4"
            >
              <template #button>
                <span @click="openPopup()">{{ captchaSendMsg }}</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="van-btn" @click="search()">
          查 询
        </div>
      </div>
    </div>
    <van-popup
      v-model="codePopup"
      v-if="codePopup"
      class="codePopup"
      position="center"
    >
      <div class="slide-verify">
        <div class="tit">安全验证</div>
        <div class="close" @click="codePopup = false">
          <van-icon name="cross" size="16px" />
        </div>
        <div class="slider-container">
          <div
            class="captcha-image"
            :style="`background:url(${captchaImage})`"
          ></div>
          <div
            class="slider"
            id="slider"
            :style="{
              left: sliderLeft + 'px',
              background: `url(${sliderImage})`
            }"
          ></div>
          <div
            class="slider-drag"
            :style="{
              borderColor: sliderTip === 'err' ? '#c75b4d' : '#ededed'
            }"
          >
            <div
              class="left"
              v-show="sliderTip !== 'err'"
              :style="{
                width: sliderLeft + 'px'
              }"
            ></div>
            <div
              class="tip"
              :style="{ color: sliderTip === 'err' ? '#c75b4d' : '#101010' }"
            >
              {{
                sliderTip === "err" ? "验证失败请重试" : "向右拖动滑块完成拼图"
              }}
            </div>
            <div
              class="img"
              @mousedown="startDrag"
              @touchstart="startDrag"
              v-show="sliderTip !== 'err'"
              :style="{
                left: sliderLeft + 'px'
              }"
            ></div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  sliderCaptcha,
  verifyCode,
  verifySmsCode
} from "@/services/HomeServices";
import MainLayout from "@/layout/MainLayout";
export default {
  name: "Institution",
  data() {
    return {
      searchForm: {
        number: "",
        code: ""
      },
      captchaSendMsg: "获取验证码",
      type: "search", //页面切换
      codePopup: false, //验证码弹窗
      captchaImage: "", //验证图片
      sliderLeft: 0, //滑动距离
      startX: 0,
      sliderWidth: 50, //滑块宽度
      viewWidth: 300, //背景图片宽度
      sliderX: "",
      counter: 60, //验证码获取倒计时
      reSendAllow: true,
      sliderImage: "", //滑块图片
      sliderTip: "", //校验结果
    };
  },
  created() {
    this.$emit(`update:layout`, MainLayout);
    this.$store.dispatch("changeTab", "inquiry");
  },
  mounted() {},
  methods: {
    startDrag(event) {
      // 阻止页面滚动
      event.preventDefault();
      let clientX = event.clientX || event.changedTouches[0].clientX;
      this.startX = clientX - this.sliderLeft;
      document.onmousemove = this.onDrag;
      document.ontouchmove = this.onDrag;
      document.onmouseup = this.endDrag;
      document.ontouchend = this.endDrag;
    },
    onDrag(event) {
      let clientX = event.clientX || event.changedTouches[0].clientX;
      let moveX = clientX - this.startX;
      if (moveX < 0) moveX = 0;
      if (moveX > this.viewWidth - this.sliderWidth)
        moveX = this.viewWidth - this.sliderWidth;
      this.sliderLeft = moveX;
    },
    endDrag() {
      document.onmousemove = null;
      document.ontouchmove = null;
      document.onmouseup = null;
      document.ontouchend = null;
      this.verifyCaptcha();
    },
    verifyCaptcha() {
      verifyCode({
        mobile: this.searchForm.number,
        corPosition: this.sliderX,
        sliderX: Math.round(this.sliderLeft),
        loginType: 1
      }).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.sliderLeft = 0;
          this.codePopup = false;
          Toast("发送成功");
          this.countDown();
        } else {
          this.codePopup = false;
          Toast(resp.data.errorMsg);
        }
      });
    },
    isValidInternationalPhoneNumber(phoneNumber) {
      const regex = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
      return regex.test(phoneNumber);
    },
    validateEmail(str) {
      const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      return reg.test(str);
    },
    openPopup() {
      if (!this.reSendAllow) {
        return;
      }
      if (!this.searchForm.number) {
        Toast("请输入手机号");
        return;
      }
      if (!this.isValidInternationalPhoneNumber(this.searchForm.number)) {
        Toast("请输入正确的手机号或者邮箱");
        return;
      }
      sliderCaptcha().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.codePopup = true;
          this.captchaImage = resp.data.data.image;
          this.sliderImage = resp.data.data.sliderImage;
          this.sliderX = resp.data.data.sliderX;
        } else {
          Toast(resp.data.errorMsg || "服务异常");
        }
      });
    },
    // 验证码 秒数
    countDown() {
      this.reSendAllow = false;
      let num = this.counter;
      this.captchaSendMsg = "重新获取" + num + "秒";
      for (let i = num; i >= 0; i--) {
        setTimeout(() => {
          this.captchaSendMsg = "重新获取" + i + "秒";
          if (i === 0) {
            this.captchaSendMsg = "重新获取";
            this.reSendAllow = true;
          }
        }, (num - i + 1) * 1000);
      }
    },
    search() {
      let params = {};
      if (!this.searchForm.number) {
        Toast("请输入手机号或者邮箱");
        return;
      }
      if (
        !this.isValidInternationalPhoneNumber(this.searchForm.number) &&
        !this.validateEmail(this.searchForm.number)
      ) {
        Toast("请输入正确的手机号或者邮箱");
        return;
      }
      if (!this.searchForm.code) {
        Toast("请输入验证码");
        return;
      }
      params.captcha = this.searchForm.code;
      params.concat = this.searchForm.number;
      Toast.loading({
        message: "查询中...",
        forbidClick: true,
        duration: 0
      });
      verifySmsCode(params).then(resp => {
        Toast.clear();
        if (resp.data.code === "SUCCESS") {
          let shortCode = resp.data.data;
          this.$router.push({ path: `/certificates/${shortCode}` });
        } else {
          Toast(resp.data.errorMsg || "服务异常");
        }
      });
    },
    next() {
      this.searchForm.number = "";
      this.searchForm.code = "";
      this.type = "search";
    },
  }
};
</script>

<style scoped lang="less">
.main-container {
  background-color: #ffffff;
  /* prettier-ignore */
  font-size: 12PX;
  margin: 0 auto;
  /* prettier-ignore */
  max-width:900PX;
  .banner {
    width: 100%;
    background: #f7f7f7;
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      /* prettier-ignore */
      max-width: 1000PX;
    }
  }
  .search {
    padding: 3.6rem 2.4rem;
    .title {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }
    .form {
      margin-top: 4rem;
      .form_item {
        display: flex;
        align-items: center;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px solid #ededed;
        font-size: 1.4rem;
        height: 6rem;
        .label {
          width: 12rem;
          padding-right: 1rem;
          .en {
            color: #999;
            margin-top: 0.2rem;
          }
        }
        .value {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .van-cell {
            padding: 0;
          }
        }
      }
      .van-btn {
        background: #14a9d0;
        color: #fff;
        text-align: center;
        line-height: 3.8rem;
        margin-top: 3rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
      }
      .downTime {
        text-align: center;
        color: #666666;
        margin-top: 1.2rem;
      }
    }
  }
  .success {
    padding: 1.2rem 2.4rem;
    .tit {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 1.8rem;
      margin-bottom: 0.5rem;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      .num_box {
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          width: 15rem;
          height: 15rem;
          position: relative;
          .img_l {
            position: absolute;
            top: 0;
            left: -1rem;
            width: 7.5rem;
            height: 15rem;
            overflow: hidden;
            transform: rotate(-10deg);
            img {
              width: 15rem;
              height: 15rem;
            }
          }
          .img_r {
            position: absolute;
            top: 0;
            right: -1rem;
            width: 7.5rem;
            height: 15rem;
            overflow: hidden;
            transform: rotate(10deg);
            img {
              width: 15rem;
              height: 15rem;
              margin-left: -7.5rem;
            }
          }
          .number {
            width: 15rem;
            position: absolute;
            left: 0;
            top: 4.2rem;
            text-align: center;
            font-size: 2rem;
            color: #fdc73c;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .txt {
              font-size: 1.2rem;
              margin-top: 0.3rem;
            }
          }
        }
      }
    }

    .btn {
      width: 12rem;
      display: flex;
      align-items: center;
      border: 1px solid #14a9d0;
      color: #14a9d0;
      height: 3.2rem;
      border-radius: 3.2rem;
      justify-content: center;
      font-size: 1.4rem;
      margin: -2rem auto 0.2rem;
      position: relative;
      z-index: 19;
    }
    .downTime {
      text-align: center;
      color: #666666;
      margin-top: 1.2rem;
    }
    .tabN {
      display: flex;
      font-size: 1.4rem;
      margin-top: 1.8rem;
      border-bottom: 1px solid #ededed;
      .item {
        line-height: 3.6rem;
        margin-right: 2.2rem;
      }
      .active {
        color: #14a9d0;
        font-size: 1.6rem;
        font-weight: 600;
        border-bottom: 1px solid;
      }
    }
    .tab {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.4rem;
      margin-top: 1.8rem;
      .item {
        margin-right: 0.6rem;
        padding: 0.6rem;
        margin-bottom: 0.5rem;
        color: #666;
      }
      .active {
        color: #14a9d0;
        background-color: rgba(235, 246, 251, 1);
      }
    }
    .list {
      display: flex;
      padding: 0 3.2rem;
      flex-wrap: wrap;
      .item {
        width: 50%;
        padding: 0 1.5rem;
        margin-top: 1.6rem;
        .img {
          border: 0.5rem solid #b59f75;
          padding: 0.2rem;
          position: relative;
          img {
            width: 100%;
            display: block;
          }
          .item-down {
            position: absolute;
            left: -0.5rem;
            bottom: -0.5rem;
            right: -0.5rem;
            background-color: rgba(51, 51, 51, 0.72);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #fff;
            height: 3rem;
            padding: 0 1rem;
            .on-down {
              display: flex;
              align-items: center;
              height: 3rem;
              img {
                width: 1.6rem;
                height: 1.6rem;
              }
              span {
                font-size: 1.2rem;
                margin-left: 0.2rem;
              }
            }
          }
        }
        .item-c {
          text-align: center;
          margin-top: 0.8rem;
          font-size: 1.2rem;
          color: #666666;
        }
        .name {
          text-align: center;
          margin-top: 0.3rem;
          word-wrap: break-word;
          font-size: 1.2rem;
          color: #666666;
        }
      }
    }
    .empty {
      img {
        width: 13rem;
        display: block;
        margin: 2.4rem auto 1.2rem;
      }
      .tip {
        text-align: center;
        color: #999;
        margin-top: 1.2rem;
      }
    }
  }
}
/deep/ .van-field__button {
  background: #f0f0f0;
  padding: 0 0.8rem;
  border-radius: 0.5rem;
  font-weight: 600;
  line-height: 3rem;
}
.slide-verify {
  padding: 1.2rem 1.6rem 1.6rem 1.6rem;
  /* prettier-ignore */
  max-width: 500PX;
  position: relative;
  .tit {
    /* prettier-ignore */
    font-size: 18PX;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.2rem;
    /* prettier-ignore */
    line-height: 28PX;
  }
  .close {
    position: absolute;
    right: 1.6rem;
    top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* prettier-ignore */
    height: 28PX;
  }
  .slider-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    /* prettier-ignore */
    height: 200PX;
    .captcha-image {
      /* prettier-ignore */
      width: 300PX;
      /* prettier-ignore */
      height: 150PX;
      overflow: hidden;
      background-size: cover;
      img {
        display: block;
        /* prettier-ignore */
        width: 300PX;
        /* prettier-ignore */
        height: 150PX;
      }
    }
    .slider {
      /* prettier-ignore */
      width: 50PX;
      /* prettier-ignore */
      height: 50PX;
      box-shadow: #83c243 0px 3px 10px 3px;
      position: absolute;
      cursor: pointer;
      /* prettier-ignore */
      top: 50PX;
      background-size: cover !important;
      display: block;
    }
    .slider-drag {
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      /* prettier-ignore */
      height: 36PX;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 0.5rem;
      .tip {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /* prettier-ignore */
        line-height: 34PX;
        text-align: center;
      }
      .left {
        /* prettier-ignore */
        height: 34PX;
        background: #83c243;
        width: 0px;
      }
      .img {
        /* prettier-ignore */
        width: 50PX;
        /* prettier-ignore */
        height: 34PX;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../assets/slider.png");
        /* prettier-ignore */
        background-size: 20PX 20PX;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        /* prettier-ignore */
        border-left: 1PX solid #ededed;
        border-right: 1px solid #ededed;
        z-index: 9;
        top: 0;
        background-color: #fff;
      }
    }
  }
}
.support {
  text-align: center;
  font-size: 1.2rem;
  color: #999999;
  padding-bottom: 3rem;
  margin-top: 6rem;
  a {
    color: #999999 !important;
  }
}
.support_n {
  text-align: center;
  font-size: 1.2rem;
  color: #999999;
  padding-bottom: 3rem;
  margin-top: 3rem;
  a {
    color: #999999 !important;
  }
}
</style>
<style scoped lang="less">
@media screen and (min-width: 500px) {
  .search {
    /* prettier-ignore */
    padding: 36PX 120PX !important;
    .title {
      /* prettier-ignore */
      font-size: 26PX !important;

      /* prettier-ignore */
      margin-top: 24PX;
    }
    .form {
      /* prettier-ignore */
      margin-top: 60PX !important;
      .form_item {
        /* prettier-ignore */
        height: 80PX !important;
        .label {
          /* prettier-ignore */
          font-size: 20PX !important;
          /* prettier-ignore */
          width: 180PX !important;
        }
        /deep/ .van-field__control {
          /* prettier-ignore */
          font-size: 20PX !important;
        }
        /deep/ .van-field__button {
          /* prettier-ignore */
          font-size: 20PX !important;
        }
      }
      .van-btn {
        /* prettier-ignore */
        font-size: 26PX !important;
      }
      .downTime {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
    }
  }
  .success {
    .tit {
      /* prettier-ignore */
      font-size: 26PX !important;
    }
    .info {
      .img {
        .txt {
          /* prettier-ignore */
          bottom: 10PX !important;
          /* prettier-ignore */
          font-size: 20PX !important;
        }
      }
    }
    .btn {
      /* prettier-ignore */
      margin: 16PX auto !important;
      /* prettier-ignore */
      font-size: 20PX !important;
      /* prettier-ignore */
      height: 56PX !important;
      /* prettier-ignore */
      width: 200PX !important;
    }
    .downTime {
      /* prettier-ignore */
      font-size: 20PX !important;
    }
    .tabN {
      .item {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
      .active {
        /* prettier-ignore */
        font-size: 24PX !important;
      }
    }
    .tab {
      /* prettier-ignore */
      margin-top: 24PX !important;
      .item {
        /* prettier-ignore */
        font-size: 20PX !important;
      }
    }
    .list {
      .item {
        .item-c {
          /* prettier-ignore */
          font-size: 18PX !important;
        }
        .name {
          /* prettier-ignore */
          font-size: 18PX !important;
        }
      }
    }
    .empty {
      .tip {
        /* prettier-ignore */
        font-size: 18PX !important;
      }
    }
  }
  .support {
    /* prettier-ignore */
    font-size: 16PX !important;
  }
  .on-down {
    span {
      /* prettier-ignore */
      font-size: 18PX !important;
    }
  }
}
</style>
