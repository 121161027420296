var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'search'),expression:"type === 'search'"}],staticClass:"search"},[_c('div',{staticClass:"title"},[_vm._v("参赛证书查询")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form_item"},[_vm._m(1),_c('div',{staticClass:"value"},[_c('van-field',{attrs:{"type":"text","label":"","placeholder":"请输入预留手机号"},model:{value:(_vm.searchForm.number),callback:function ($$v) {_vm.$set(_vm.searchForm, "number", $$v)},expression:"searchForm.number"}})],1)]),_c('div',{staticClass:"form_item"},[_vm._m(2),_c('div',{staticClass:"value"},[_c('van-field',{attrs:{"type":"text","label":"","placeholder":"请输入验证码","autocomplete":"one-time-code","inputmode":"numeric","maxlength":"4"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openPopup()}}},[_vm._v(_vm._s(_vm.captchaSendMsg))])]},proxy:true}]),model:{value:(_vm.searchForm.code),callback:function ($$v) {_vm.$set(_vm.searchForm, "code", $$v)},expression:"searchForm.code"}})],1)]),_c('div',{staticClass:"van-btn",on:{"click":function($event){return _vm.search()}}},[_vm._v(" 查 询 ")])])]),(_vm.codePopup)?_c('van-popup',{staticClass:"codePopup",attrs:{"position":"center"},model:{value:(_vm.codePopup),callback:function ($$v) {_vm.codePopup=$$v},expression:"codePopup"}},[_c('div',{staticClass:"slide-verify"},[_c('div',{staticClass:"tit"},[_vm._v("安全验证")]),_c('div',{staticClass:"close",on:{"click":function($event){_vm.codePopup = false}}},[_c('van-icon',{attrs:{"name":"cross","size":"16px"}})],1),_c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"captcha-image",style:(`background:url(${_vm.captchaImage})`)}),_c('div',{staticClass:"slider",style:({
            left: _vm.sliderLeft + 'px',
            background: `url(${_vm.sliderImage})`
          }),attrs:{"id":"slider"}}),_c('div',{staticClass:"slider-drag",style:({
            borderColor: _vm.sliderTip === 'err' ? '#c75b4d' : '#ededed'
          })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sliderTip !== 'err'),expression:"sliderTip !== 'err'"}],staticClass:"left",style:({
              width: _vm.sliderLeft + 'px'
            })}),_c('div',{staticClass:"tip",style:({ color: _vm.sliderTip === 'err' ? '#c75b4d' : '#101010' })},[_vm._v(" "+_vm._s(_vm.sliderTip === "err" ? "验证失败请重试" : "向右拖动滑块完成拼图")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sliderTip !== 'err'),expression:"sliderTip !== 'err'"}],staticClass:"img",style:({
              left: _vm.sliderLeft + 'px'
            }),on:{"mousedown":_vm.startDrag,"touchstart":_vm.startDrag}})])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/head-banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('div',{staticClass:"cn"},[_vm._v("预留手机号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('div',{staticClass:"cn"},[_vm._v("验证码")])])
}]

export { render, staticRenderFns }