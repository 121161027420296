import axios from "axios";
const host = process.env.VUE_APP_API_HOST;
const imgHost = process.env.VUE_APP_IMG_HOST;
const baseURL = `${host}/api`;
// 附件基础路径
const imageBaseUrl = `${imgHost}/`;

const apiClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
const downloadApiClient = axios.create({
  baseURL,
  withCredentials: false,
  responseType: "blob"
});

export { apiClient, downloadApiClient, baseURL, imageBaseUrl };
