<template>
  <div class="jptab">
    <div class="jptab_panel">
      <slot />
      <div class="bottom-holder"></div>
    </div>
    <div class="jptab_tabbar">
      <div class="jptab_tabbar_item" @click="onClickInquiry">
        <img :src="tabIcon('inquiry')" alt="证书查询" class="jptab_tabbar_item_icon" />
        <p class="jptab_tabbar_item_label">证书查询</p>
      </div>
      <div class="jptab_tabbar_item" @click="onClickGallery">
        <img :src="tabIcon('gallery')" alt="作品展示" class="jptab_tabbar_item_icon" />
        <p class="jptab_tabbar_item_label">作品展示</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    //tab点击事件处理
    onClickGallery() {
      this.$store.dispatch("changeTab", "gallery");
      this.$router.push({ name: "Gallery" });
    },
    onClickInquiry() {
      this.$store.dispatch("changeTab", "inquiry");
      this.$router.push({ name: "Inquiry" });
    },
    //tab icon显示
    tabIcon(tabName) {
      if (this.$store.state.activeTab === tabName) {
        return `/${tabName}-on.png`;
      } else {
        return `/${tabName}-off.png`;
      }
    },

  },
};
</script>

<style lang="less" scoped>
.bottom-holder {
  height: 6rem;
}
.jptab {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  &_panel {
    flex-grow: 1;
    overflow: auto;
  }

  &_tabbar {
    position: fixed;
    width: 100%;
    max-width: 600px;
    bottom: 0;
    background-color: white;
    display: flex;
    border-top: 1px solid #efefef;

    &_item {
      display: block;
      flex-grow: 1;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;

      &_icon {
        width: 2rem;
        height: 2rem;
        margin-bottom: 2px;
      }

      &_label {
        font-size: 1.3rem;
        line-height: 1.4;
      }
    }
  }
}
.rank-title-container {
  font-size: 1.6rem;
}
</style>
